import { connect } from 'react-redux'
import {  } from '../actions'
import App from '../components/App'

const mapStateToProps = state => ({

})
// Maybe we can do an error for each resource
  
const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)